import React from 'react'
/** @jsx jsx */
import { jsx, Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'

import { SEO } from 'components'

import { useAppContext } from 'utils/app-context'

function Accommodation() {
  const { setCurrentModal } = useAppContext()

  return (
    <>
      <SEO title="Motor Homes and Camper Vans" />

      <Box sx={{ bg: 'black.5' }}>
        <Box
          sx={{
            paddingTop: ['xl', null, 'xxl', 'xxxl'],
            px: 'm',
            maxWidth: 'measure',
            mx: 'auto',
            boxSizing: 'content-box',
          }}
        >
          <Heading variant="heading.l" mb={['s']}>
            Motor Homes and Camper Vans
          </Heading>
          <p>
            At The Station Inn, we welcome customers with Motorhomes or
            Campervans to park overnight in our carpark. It's our pleasure to
            offer this service for free, but we kindly ask that you use the Inn
            for food and drink during your stay. Pre-booking is not available,
            and we operate on a "first come, first served" basis. To avoid
            disappointment during busy times, such as weekends and Bank
            Holidays, we recommend arriving early. If the car park is full,
            there are many laybys around the Inn that you can also use.
          </p>
          <p>
            For your convenience, we provide both portaloo's and overnight
            toilet and sink facilities, as well as a waste disposal facility.
            Unfortunately, we do not offer Electric Hook Up services.
          </p>
          <p>
            Upon arrival, simply book in at the Bar, and we will provide you
            with a code for the out of hours toilets and a ticket to display in
            your vehicle for the duration of your stay. You can also make an
            arrangement for an evening meal, either in the Inn or in your own
            camper. Please understand that flexibility over timings is
            appreciated.
          </p>
          <p>
            To ensure maximum occupancy is achieved, we kindly ask that you park
            respectfully and closely to other vehicles, which benefits everyone.
          </p>
          <p>
            Please note that there is no camping available on-site. While some
            have chosen to camp over the back wall of the Inn on Batty Moss, it
            should be noted that this is private land and not common ground, so
            we kindly ask that you refrain from doing so.
          </p>

          <Box sx={{ py: ['xl', null, 'xxl'] }}>
            <Box
              sx={{
                display: [null, null, 'flex'],
                marginBottom: 'l',
                justifyContent: 'center',
              }}
            >
              <Button as={Link} to="/accommodation" mr="m" mb={['s', null, 0]}>
                Book our rooms
              </Button>
              <Button
                onClick={() => setCurrentModal('availability')}
                mr="m"
                mb={['s', null, 0]}
                variant="secondary"
              >
                Check availability
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Accommodation
